import AenderungsmodusPatch from '@src/model/aenderungsmodus/AenderungsmodusPatch';
import AenderungsmodusPatchBody from '@src/model/aenderungsmodus/AenderungsmodusPatchBody';
import AenderungsmodusMode from '@src/model/enums/AenderungsmodusMode';
import AenderungsmodusSectionState from '@src/model/enums/AenderungsmodusSectionState';
import { authWrapper } from '@src/services/axios';
import ClientElementLayout from '@src/model/ClientElementLayout';
import {
  deepClone, isEmpty,
  isEqual
} from '@st/utils-js';
import moment from 'moment';
import MandantDokumentElement from '../../../../model/MandantDokumentElement';
import MandantDokumentElementClientElement from '../../../../model/MandantDokumentElementClientElement';
import services from '../../../../services';
import Patcher from '../../../../utils/Patcher';
import types from '../../../types';
import * as helper from './helper';

const mandantEditorActions = types.actions.mandantEditor;
const mandantEditorMutations = types.mutations.mandantEditor;
const mandantEditorGetters = types.getters.mandantEditor;

const viewContentMutations = types.mutations.viewContent;

const sharedEditorMutations = types.mutations.sharedEditor;

export default {
  [mandantEditorActions.FETCH_CLIENT_ELEMENTS]({ commit }, clientId) {
    const prm = new Promise((resolve, reject) => {
      services.editor.client.getClientElementList(clientId)
        .then((result) => {
          commit(mandantEditorMutations.SET_CLIENT_ELEMENTS, {
            clientElements: result?.data?.results
          });
          resolve(result);
        })
        .catch((error) => reject(error));
    });

    return authWrapper({ commit }, prm);
  },

  [mandantEditorActions.FETCH_CLIENT_ELEMENT]({ commit }, { elementId, revision, subElementId }) {
    const prm = new Promise((resolve, reject) => {
      services.editor.client.getClientElement(elementId, revision)
        .then((response) => {
          const tmpClientElement = MandantDokumentElement.fromJSON(response.data);

          if (!!subElementId && !tmpClientElement.clientElement.subElements[subElementId]) {
            const error = new Error('Dokument im Vertrag nicht gefunden');
            error.response = {
              status: 404
            };
            reject(error);
          }

          commit(mandantEditorMutations.SET_CLIENT_ELEMENT, tmpClientElement);
          resolve(tmpClientElement);
        })
        .catch((error) => {
          commit(
            `${viewContentMutations.SHOW_SNACKBAR_ERROR}`,
            'Vertrag/Dokument ist nicht valide'
          );
          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },
  [mandantEditorActions.FETCH_CLIENT_ELEMENT_LAYOUT]({ commit }, {
    clientElementId
  }) {
    const prm = new Promise((resolve, reject) => {
      services.editor.client.getClientElementLayout(clientElementId)
        .then((result) => {
          const { layout = {} } = result.data;

          const tmpClientElementLayout = new ClientElementLayout(
            layout.layout,
            layout.ueberschriftSchriftgroesse,
            layout.textSchriftgroesse,
            layout.ueberschriftSchriftart,
            layout.textSchriftart,
            layout.abstandOben,
            layout.abstandUnten,
            layout.abstandRechts,
            layout.abstandLinks,
            layout.abstandAbsatz,
            layout.abstandVorUeberschrift,
            layout.abstandSpalten,
            layout.textIstFett,
            layout.textIstUnterstrichen,
            layout.textIstKursiv,
            layout.ueberschriftIstFett,
            layout.ueberschriftIstUnterstrichen,
            layout.ueberschriftIstKursiv,
            layout.textSchriftFarbe,
            layout.ueberschriftSchriftFarbe
          );

          commit(mandantEditorMutations.SET_CLIENT_ELEMENT_LAYOUT, {
            layout: tmpClientElementLayout,
            sectionLayouts: result.data.sectionLayouts ?? []
          });
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [mandantEditorActions.SET_CLIENT_ELEMENT_LAYOUT]({ commit }, {
    clientElementId,
    layout
  }) {
    const prm = new Promise((resolve, reject) => {
      services.editor.client.putClientElementLayout(clientElementId, layout)
        .then((result) => {
          const mappedLayout = new ClientElementLayout(
            layout.layout,
            layout.ueberschriftSchriftgroesse,
            layout.textSchriftgroesse,
            layout.ueberschriftSchriftart,
            layout.textSchriftart,
            layout.abstandOben,
            layout.abstandUnten,
            layout.abstandRechts,
            layout.abstandLinks,
            layout.abstandAbsatz,
            layout.abstandVorUeberschrift,
            layout.abstandSpalten,
            layout.textIstFett,
            layout.textIstUnterstrichen,
            layout.textIstKursiv,
            layout.ueberschriftIstFett,
            layout.ueberschriftIstUnterstrichen,
            layout.ueberschriftIstKursiv,
            layout.textSchriftFarbe,
            layout.ueberschriftSchriftFarbe
          );
          commit(mandantEditorMutations.SET_CLIENT_ELEMENT_LAYOUT, {
            layout: mappedLayout
          });
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [mandantEditorActions.SET_CLIENT_ELEMENT_SECTION_LAYOUT]({ commit }, {
    clientElementId,
    sectionId,
    layout
  }) {
    const prm = new Promise((resolve, reject) => {
      services.editor.client.putSectionLayout(clientElementId, sectionId, layout)
        .then((result) => {
          commit(mandantEditorMutations.SET_CLIENT_ELEMENT_SECTION_LAYOUT, {
            sectionId,
            layout
          });
          commit(sharedEditorMutations.UPDATE_SECTION_RERENDER_MAP, {
            id: sectionId
          });
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },
  [mandantEditorActions.DELETE_CLIENT_ELEMENT_SECTION_LAYOUT]({ commit }, {
    clientElementId,
    sectionId
  }) {
    const prm = new Promise((resolve, reject) => {
      services.editor.client.deleteSectionLayout(clientElementId, sectionId)
        .then((result) => {
          commit(mandantEditorMutations.DELETE_CLIENT_ELEMENT_SECTION_LAYOUT, {
            sectionId
          });
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [mandantEditorActions.PUT_CLIENT_ELEMENT_METADATA]({ commit }, {
    clientElementId,
    clientElementName,
    clientElementNotes
  }) {
    const prm = new Promise((resolve, reject) => {
      services.editor.client.putClientElementMetadata({
        clientElementId,
        clientElementName,
        clientElementNotes
      })
        .then(() => {
          commit(mandantEditorMutations.SET_CLIENT_ELEMENT_NAME, {
            clientElementId,
            clientElementName
          });
          commit(mandantEditorMutations.SET_CLIENT_ELEMENT_NOTES, {
            clientElementId,
            clientElementNotes
          });
          resolve(clientElementName);
        })
        .catch((error) => reject(error));
    });

    return authWrapper({ commit }, prm);
  },

  /**
   * Erstellt ein neues Dokument und es wird ein Patch an die Middleware verschickt.
   * @param {*} param0
   * @param {MandantDokumentElementClientElement} clientElement
   */
  [mandantEditorActions.ADD_CLIENT_ELEMENT_NEW_ELEMENT](
    { commit },
    {
      clientElementId,
      revision,
      clientElement,
      newSubelement
    }
  ) {
    if (!(clientElement instanceof MandantDokumentElementClientElement)) {
      return Promise.resolve(null);
    }

    const currentClientElementJSON = deepClone(clientElement.toJSON());
    const currentClientElement = MandantDokumentElementClientElement
      .fromJSON(currentClientElementJSON);

    currentClientElement.addSubelement(newSubelement);

    const patches = Patcher.createPatches(clientElement.toJSON(), currentClientElement.toJSON());

    const prm = new Promise((resolve, reject) => {
      services.editor.client.patchClientElement({
        clientElementId,
        patches,
        revision
      }).then(() => {
        resolve(newSubelement);
      }).catch((error) => {
        const msg = 'Dokument konnte nicht hinzugefügt werden';
        commit(viewContentMutations.SHOW_SNACKBAR_ERROR, msg);
        reject(error);
      });
    });

    return authWrapper({ commit }, prm);
  },

  /**
   * Löscht ein Dokument und es wird ein Patch an die Middleware verschickt.
   * @param {*} param0
   * @param {MandantDokumentElementClientElement} clientElement
   */
  [mandantEditorActions.REMOVE_CLIENT_ELEMENT_SUBELEMENT](
    { commit },
    {
      clientElementId,
      revision,
      clientElement,
      subElementId
    }
  ) {
    if (!(clientElement instanceof MandantDokumentElementClientElement)) {
      return Promise.resolve(false);
    }
    const currentClientElementJSON = deepClone(clientElement.toJSON());
    const currentClientElement = MandantDokumentElementClientElement
      .fromJSON(currentClientElementJSON);

    currentClientElement.removeSubelement(subElementId);

    const patches = Patcher.createPatches(clientElement.toJSON(), currentClientElement.toJSON());

    const prm = new Promise((resolve, reject) => {
      services.editor.client.patchClientElement({
        clientElementId,
        patches,
        revision
      }).then(() => {
        resolve(true);
      }).catch((error) => {
        const msg = 'Element konnte nicht gelöscht werden';
        commit(viewContentMutations.SHOW_SNACKBAR_ERROR, msg);
        reject(error);
      });
    });

    return authWrapper({ commit }, prm);
  },

  [mandantEditorActions.DEPRECATE_CLIENT_ELEMENT]({ commit }, { clientElementId }) {
    const prm = new Promise((resolve, reject) => {
      services.editor.client.deleteClientElement(clientElementId)
        .then((result) => {
          commit(mandantEditorMutations.DEPRECATE_CLIENT_ELEMENT, { clientElementId });
          resolve(result);
        })
        .catch((error) => {
          commit(viewContentMutations.SHOW_SNACKBAR_ERROR, error.message);

          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [mandantEditorActions.PUT_CLIENT_ELEMENT_RELEASE_FLAG]({ commit }, {
    clientElementId,
    freigabeStatus
  }) {
    const prm = new Promise((resolve, reject) => {
      services.editor.client.putClientElementReleaseFlag(clientElementId, freigabeStatus)
        .then((result) => {
          commit(
            mandantEditorMutations.SET_CLIENT_ELEMENT_RELEASE_FLAG,
            { clientElementId, freigabeStatus }
          );
          resolve(result);
        })
        .catch((error) => {
          commit(viewContentMutations.SHOW_SNACKBAR_ERROR, error.message);

          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [mandantEditorActions.PUT_CLIENT_ELEMENT_TAGS]({ commit }, { clientElementId, tagsObj }) {
    const tagIds = tagsObj.tags.map((tag) => tag.id);
    const prm = new Promise((resolve, reject) => {
      services.editor.client.putClientElementTags(clientElementId, { tags: tagIds })
        .then((result) => {
          commit(
            mandantEditorMutations.SET_CLIENT_ELEMENT_TAGS,
            { clientElementId, tags: tagsObj.tags }
          );
          resolve(result);
        })
        .catch((error) => {
          commit(viewContentMutations.SHOW_SNACKBAR_ERROR, error.message);

          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [mandantEditorActions.GET_CLIENT_ELEMENT_UPDATES]({ commit }, { clientElementId }) {
    const prm = new Promise((resolve, reject) => {
      services.editor.client.getClientElementUpdates(clientElementId)
        .then((result) => {
          commit(mandantEditorMutations.SET_CLIENT_ELEMENT_UPDATES, {
            updates: result.data.updates
          });
          resolve(result);
        })
        .catch((error) => {
          commit(viewContentMutations.SHOW_SNACKBAR_ERROR, error.message);

          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  // Updates
  [mandantEditorActions.GET_CLIENT_ELEMENT_ZWANGSUPDATE]({ commit }, { clientElementId }) {
    return new Promise((resolve, reject) => {
      services.client.update.getZwangsupdate(clientElementId)
        .then((result) => {
          const zwangsupdate = result.data;
          commit(mandantEditorMutations.SET_CLIENT_ELEMENT_ZWANGSUPDATE, zwangsupdate);
          resolve(result);
        })
        .catch((error) => reject(new Error(error.message)));
    });
  },
  [mandantEditorActions.SET_UPDATE_COMPLETED]({ commit }, { clientId, clientElementId }) {
    const prm = new Promise((resolve, reject) => {
      services.editor.mandantUpdate.setUpdateCompleted(clientId, clientElementId)
        .then((result) => {
          commit(mandantEditorMutations.SET_UPDATE_COMPLETED, { clientElementId });
          commit(mandantEditorMutations.UPDATE_MANDANT_REVISION);
          resolve(result);
        }).catch((error) => {
          commit(viewContentMutations.SHOW_SNACKBAR_ERROR, error.message);

          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },
  [mandantEditorActions.REMOVE_CLIENT_ELEMENT_ZWANGSUPDATE_KEY](
    { state, commit },
    { clientId, clientElementId, sectionId }
  ) {
    if (!(state.clientElementZwangsupdate?.[sectionId])) return null;

    const prm = new Promise((resolve, reject) => {
      services.editor.mandantUpdate.deleteZwangsupdateSection(clientId, clientElementId, sectionId)
        .then((result) => {
          commit(
            mandantEditorMutations.REMOVE_CLIENT_ELEMENT_ZWANGSUPDATE_KEY,
            sectionId
          );
          resolve(result);
        }).catch((error) => {
          commit(viewContentMutations.SHOW_SNACKBAR_ERROR, error.message);

          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [mandantEditorActions.START_AENDERUNGSMODUS]({ commit, dispatch }, {
    clientElementId,
    subElementId
  }) {
    const prm = new Promise((resolve, reject) => {
      services.mandant.aenderungsmodus.startRecording(clientElementId)
        .then((result) => {
          dispatch(mandantEditorActions.GET_AENDERUNGSMODUS_HISTORY, {
            clientElementId,
            subElementId
          });
          resolve(result);
        })
        .catch((error) => {
          commit(viewContentMutations.SHOW_SNACKBAR_ERROR, error.message);

          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [mandantEditorActions.END_AENDERUNGSMODUS]({ commit }, {
    clientElementId,
    mode
  }) {
    if (!AenderungsmodusMode.isValidState(mode)) throw new Error('Invalid mode');

    const prm = new Promise((resolve, reject) => {
      services.mandant.aenderungsmodus.stopRecording(clientElementId, mode)
        .then((result) => {
          commit(mandantEditorMutations.SET_AENDERUNGSMODUS, false);
          resolve(result);
        })
        .catch((error) => {
          commit(viewContentMutations.SHOW_SNACKBAR_ERROR, error.message);

          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [mandantEditorActions.GET_AENDERUNGSMODUS_HISTORY]({ state, commit }, {
    clientElementId,
    subElementId
  }) {
    const prm = new Promise((resolve, reject) => {
      services.mandant.aenderungsmodus.getHistory(clientElementId, subElementId)
        .then((result) => {
          commit(mandantEditorMutations.SET_AENDERUNGSMODUS_HISTORY, result.data);
          commit(mandantEditorMutations.SET_AENDERUNGSMODUS, result.status);
          if (result.status) {
            commit(mandantEditorMutations.PREPARE_CLIENT_ELEMENT_FOR_AENDERUNGSMODUS, {
              originClientElement: state.clientElement,
              subelementRecord: result.data.subelementRecord,
              subElementId,
              aenderungsmodusSections: result.data.sections
            });
          }
          resolve(result.status);
        })
        .catch((error) => {
          commit(viewContentMutations.SHOW_SNACKBAR_ERROR, error.message);

          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [mandantEditorActions.SET_AENDERUNGSMODUS_SECTION_STATE]({ state, commit, rootState }, {
    sectionId
  }) {
    const section = state.aenderungsmodusHistory.sections[sectionId];
    const latestSectionState = section?.patches.at(-1)?.state;
    const sectionState = AenderungsmodusSectionState.isNew(latestSectionState)
      ? AenderungsmodusSectionState.NEW_DELETED
      : AenderungsmodusSectionState.EXISTING_DELETED;

    const { order } = state.clientElement.clientElement.subElements[state.currentClientElementId]
      .content;

    const patchBody = new AenderungsmodusPatchBody(
      [],
      sectionState,
      order
    ).toJSON();

    return services.mandant.aenderungsmodus.patchChanges({
      clientElementId: state.clientElement.clientElementId,
      subElementId: state.currentClientElementId,
      sectionId,
      baseRevision: section?.baseRevision ?? 0,
      baseSubRevision: section?.baseSubRevision ?? 0,
      body: patchBody
    })
      .then((response) => {
        const patch = new AenderungsmodusPatch(
          rootState.user.user.id,
          rootState.user.user.firstname,
          rootState.user.user.lastname,
          Number(moment().format('x')),
          [],
          sectionState,
          response.data.nextBaseRevision,
          response.data.nextBaseSubRevision,
          [],
          response.data.isUpdatePatch
        );

        commit(mandantEditorMutations.SET_AENDERUNGSMODUS_SECTION_REVISION_AND_ADD_PATCH, {
          sectionId,
          revision: response.data.nextBaseRevision,
          subRevision: response.data.nextBaseSubRevision,
          patch
        });

        commit(sharedEditorMutations.UPDATE_SECTION_RERENDER_MAP, {
          id: sectionId
        });

        commit(mandantEditorMutations.SET_AENDERUNGSMODUS_SELECTED_HEAD, patch);
      });
  },

  [mandantEditorActions.PATCH_AENDERUNGSMODUS_PATCHES]({
    state, commit, getters, rootState
  }, {
    clientElementId,
    subElementId,
    newSection,
    oldSection,
    sectionId,
    order,
    elementController,
    elementControllerOptions = {}
  }) {
    const patches = Patcher
      .createPatches(oldSection, newSection);

    if (patches.length < 1) return Promise.resolve();

    const historySection = getters[mandantEditorGetters.GET_AENDERUNGSMODUS_HISTORY_SECTION](
      sectionId
    );
    const oldOrder = state.clientElement.clientElement.subElements[subElementId].content.order;
    let historySectionState = helper.getAenderungsmodusSectionState(
      sectionId,
      order,
      oldOrder,
      state.aenderungsmodusHistory
    );
    if (isEmpty(oldSection)) {
      historySectionState = AenderungsmodusSectionState.NEW;
    }

    const orderChanged = !isEqual(order, oldOrder);

    const patchBody = new AenderungsmodusPatchBody(
      patches,
      historySectionState,
      order
    ).toJSON();

    const { user } = rootState.user;

    return elementController.savePatches(
      {
        clientElementId,
        subElementId,
        sectionId,
        baseRevision: 0,
        baseSubRevision: 0,
        body: patchBody
      },
      services.mandant.aenderungsmodus.patchChanges,
      {
        preparePayloadFn: (payload) => {
          const aenderungsmodusHistorySection = getters[
            mandantEditorGetters.GET_AENDERUNGSMODUS_HISTORY_SECTION
          ](
            sectionId
          );
          // eslint-disable-next-line no-param-reassign
          payload.baseRevision = aenderungsmodusHistorySection?.baseRevision ?? 0;
          // eslint-disable-next-line no-param-reassign
          payload.baseSubRevision = aenderungsmodusHistorySection?.baseSubRevision ?? 0;

          const newPatch = helper.aenderungsmodusHeadIsSetAndRevisionOfSectionIsNotLatest(
            state.aenderungsmodusSelectedHead,
            historySection
          );

          // eslint-disable-next-line no-param-reassign
          payload.newPatch = newPatch;
        },
        everySucessfullRun: (_, responseBody) => {
          const patch = new AenderungsmodusPatch(
            user.id,
            user.firstname,
            user.lastname,
            Number(moment().format('x')),
            responseBody.payload.body.patch,
            responseBody.payload.body.state,
            responseBody.response.data.nextBaseRevision,
            responseBody.response.data.nextBaseSubRevision,
            [],
            responseBody.response.data.isUpdatePatch
          );

          commit(mandantEditorMutations.SET_AENDERUNGSMODUS_SECTION_REVISION_AND_ADD_PATCH, {
            sectionId: responseBody.payload.sectionId,
            revision: responseBody.response.data.nextBaseRevision,
            subRevision: responseBody.response.data.nextBaseSubRevision,
            patch
          });

          const newHead = responseBody.payload.baseRevision < responseBody.response.data
            .nextBaseRevision;
          if (newHead) {
            commit(mandantEditorMutations.SET_AENDERUNGSMODUS_SELECTED_HEAD, patch);
          } else {
            const currentHead = state.aenderungsmodusSelectedHead;
            const updatedHead = AenderungsmodusPatch.fromJSON({
              ...currentHead,
              revision: responseBody.response.data.nextBaseRevision,
              subRevision: responseBody.response.data.nextBaseSubRevision
            });
            commit(mandantEditorMutations.SET_AENDERUNGSMODUS_SELECTED_HEAD, updatedHead);
          }

          if (orderChanged) {
            commit(mandantEditorMutations.SET_AENDERUNGSMODUS_ORIGIN_CLIENT_ELEMENT_ORDER, order);
          }
        },
        chunkPayloadKey: 'body.patch',
        ...elementControllerOptions
      }
    );
  },

  [mandantEditorActions.ADD_COMMENT_TO_PATCH]({ state, commit, rootState }, {
    subElementId,
    sectionId,
    revision,
    comment
  }) {
    const { clientElementId } = state.clientElement;

    return services.mandant.aenderungsmodus.addCommentToPatch(
      clientElementId,
      subElementId,
      sectionId,
      revision,
      comment
    )
      .then(() => {
        commit(mandantEditorMutations.ADD_COMMENT_TO_PATCH, {
          sectionId,
          revision,
          comment,
          user: rootState.user.user
        });
      });
  },

  [mandantEditorActions.COMMIT_SECTION]({ commit, dispatch }, {
    clientElementId,
    subElementId,
    sectionId,
    revision,
    subRevision,
    clientId
  }) {
    return services.mandant.aenderungsmodus.postTrackChangesSubelementSectionCommit(
      clientElementId,
      subElementId,
      sectionId,
      revision,
      subRevision
    )
      .then(() => {
        dispatch(mandantEditorActions.REMOVE_CLIENT_ELEMENT_ZWANGSUPDATE_KEY, {
          clientId,
          clientElementId,
          sectionId
        });
        commit(mandantEditorMutations.REMOVE_SECTION_FROM_AENDERUNGSMODUS_HISTORY, sectionId);
        commit(mandantEditorMutations.SET_AENDERUNGSMODUS_SELECTED_HEAD, {});
        commit(mandantEditorMutations.SET_AENDERUNGSMODUS_SELECTED_BASE, {});
      });
  },

  [mandantEditorActions.DENY_SECTION]({ commit, dispatch }, {
    clientElementId,
    subElementId,
    sectionId,
    clientId
  }) {
    return services.mandant.aenderungsmodus.postTrackChangesSubelementSectionDeny(
      clientElementId,
      subElementId,
      sectionId
    )
      .then(() => {
        dispatch(mandantEditorActions.REMOVE_CLIENT_ELEMENT_ZWANGSUPDATE_KEY, {
          clientId,
          clientElementId,
          sectionId
        });
        commit(mandantEditorMutations.REMOVE_SECTION_FROM_AENDERUNGSMODUS_HISTORY, sectionId);
        commit(mandantEditorMutations.SET_AENDERUNGSMODUS_SELECTED_HEAD, {});
        commit(mandantEditorMutations.SET_AENDERUNGSMODUS_SELECTED_BASE, {});
      });
  }
};
